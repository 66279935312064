import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin, faSquareFacebook } from '@fortawesome/free-brands-svg-icons'


function IASTeam() {
  return (
    <div className="bg-[#F0F1F2] md:h-screen pt-16" id='Team'>
        <div className="px-6 py-10 mx-auto about-title hidd ">
          <h1 className="font-bold text-center text-[#595959] capitalize md:text-3xl text-[22px] tracking-widest">MEET OUR TEAM</h1>
          <h2 className="text-center text-[#078107] font-bold text-base pt-2">A Quality And Motivated Board</h2>
          <div className="flex justify-center mx-auto">
                <span className="inline-block w-44 h-[2.5px] bg-[#078107] rounded-full opacity-50"></span>
                <span className="inline-block w-4 h-[2.5px] mx-1 bg-[#078107] rounded-full opacity-50"></span>
                <span className="inline-block w-2 h-[2.5px] bg-[#078107] rounded-full opacity-50"></span>
            </div>
        </div>
        <div className='cont hidd grid md:grid-cols-3 grid-cols-2 pb-10'>
        <div className='flex justify-center items-center flex-col '>
                <img 
          src="https://res.cloudinary.com/dtungyafl/image/upload/v1726828425/Assets/Bureau/Copy_of_20_jabeps.jpg" 
          alt="" 
          className='md:w-[250px] md:h-[250px] w-[180px] h-[180px] flex justify-center rounded-full border-2 border-green-700 object-cover' 
        />

            <div className='text-[#595959] pt-2'>
              <div className='flex justify-center'>
                <h1 className='font-semibold md:text-xl text-lg'>Emna KOUBAA</h1>
              </div>
              <div className='flex justify-center md:pt-1 md:pb-1'>
                <h2 className='text-[#078107] font-semibold text-lg'>CHAIR</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1 md:pb-0 pb-4'>
                <a href="https://www.facebook.com/profile.php?id=100041959813158"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#595959",}} /></a>
                <a href="https://www.instagram.com/emnaa_koubaa/"><FontAwesomeIcon icon={faInstagram} style={{color: "#595959",}} /></a>
                <a href="https://www.linkedin.com/in/emna-koubaa-8409462b1/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#595959",}} /></a>
              </div>
            </div>
          </div>
          <div className='flex justify-center items-center flex-col '>
          <img 
  src="https://res.cloudinary.com/dtungyafl/image/upload/v1726828435/Assets/Bureau/Copy_of_7_nxtus1.jpg"
  alt="" 
  className='md:w-[250px] md:h-[250px] w-[180px] h-[180px] flex justify-center rounded-full border-2 border-green-700 object-cover' 
/>
            <div className='text-[#595959] pt-2'>
              <div className='flex justify-center'>
                <h1 className='font-semibold md:text-xl text-lg'>Yassine GRATI</h1>
              </div>
              <div className='flex justify-center md:pt-1 md:pb-1'>
                <h2 className='text-[#078107] font-semibold text-lg'>VICE-CHAIR</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1 md:pb-0 pb-4'>
                <a href="https://www.facebook.com/yassine.grati.12"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#595959",}} /></a>
                <a href="https://www.instagram.com/grati.yassine/"><FontAwesomeIcon icon={faInstagram} style={{color: "#595959",}} /></a>
                <a href="https://www.linkedin.com/in/yassine-grati-9407932a7/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#595959",}} /></a>
              </div>
            </div>
          </div>
          <div className='flex justify-center items-center flex-col '>
          <img 
  src="https://res.cloudinary.com/dtungyafl/image/upload/v1726828425/Assets/Bureau/Copy_of_17_fhi0k1.jpg" 
  alt="" 
  className='md:w-[250px] md:h-[250px] w-[180px] h-[180px] flex justify-center rounded-full border-2 border-green-700 object-cover' 
/>
            <div className='text-[#595959] pt-2'>
              <div className='flex justify-center'>
                <h1 className='font-semibold md:text-xl text-lg'>Emna MELLOULI</h1>
              </div>
              <div className='flex justify-center md:pt-1 md:pb-1'>
                <h2 className='text-[#078107] font-semibold text-lg'>GENERAL SECRETARY</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1'>
                <a href="https://www.facebook.com/emna.mellouli.969"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#595959",}} /></a>
                <a href="https://www.instagram.com/emna_mellouli_/"><FontAwesomeIcon icon={faInstagram} style={{color: "#595959",}} /></a>
                <a href="https://www.linkedin.com/in/emna-mellouli-a757a0293/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#595959",}} /></a>
              </div>
            </div>
          </div>
          {/* <div className='flex justify-center items-center flex-col '>
            <div className='flex items-center'>
              <img src={mouadh} alt="" className='md:w-[250px] w-[180px] flex justify-center'/>
            </div>
            <div className='text-[#595959] pt-2'>
              <div className='flex justify-center md:pt-1 md:pb-1'>
                <h1 className='font-semibold md:text-xl text-lg'>Mouadh FRAJ</h1>
              </div>
              <div className='flex justify-center'>
                <h2 className='text-[#078107] font-semibold text-lg'>MEDIA MANAGER</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1'>
                <a href="https://www.facebook.com/mouad.fraj"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#595959",}} /></a>
                <a href="https://www.instagram.com/mouadh_fraj/"><FontAwesomeIcon icon={faInstagram} style={{color: "#595959",}} /></a>
                <a href="https://www.linkedin.com/in/mouadh-fraj-0ab41424b/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#595959",}} /></a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
  )
}

export default IASTeam