import React from 'react';
import { Link } from 'react-router-dom';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { quality, format } from '@cloudinary/url-gen/actions/delivery';
const getCloudinaryImageUrl = (publicId) => {
  const myImage = new CloudinaryImage(publicId, { cloudName: 'dtungyafl' });
  return myImage
    .resize(scale().width(1000))  // Resizing the image to 1000px width
    .delivery(quality('auto'))    // Automatically adjust the quality
    .delivery(format('auto'))     // Automatically select the best format (e.g., WebP)
    .toURL();                     // Returns the URL for the transformed image
};
function Units() {
  return (
    <div className='bg-white md:mb-0 mb-10'>
      <div className=' bg-[#F2F2F2] top-10 relative '>
        <div className='Title pt-8 flex flex-col items-center justify-center'>
          <h1 class="main-title hidd md:text-3xl text-[22px] font-semibold text-center text-[#253C6A] capitalize">OUR CHAPTERS AND AFFINITY GROUP</h1>
          <div class="main-title hidd mx-auto custom-line md:mt-0 -mt-1 bg-red-800 w-28 md:h-1.5 h-[0.25rem] relative"></div>
        </div>
        <div className='grid cont hidd md:grid-cols-2 grid-cols-2 lg:grid-cols-4 gap-14 items-center justify-center pt-28 pb-8 bg-cover md:ml-20' >
            <Link to='' onClick={() => window.location.href='/IAS'} className='lg:w-[11rem] lg:h-[11rem] md:w-[11rem] md:h-[9rem] w-[11rem] h-[9rem] hover:border hover:border-[#078107] items-center flex rounded-3xl gap-4 flex-col '>
              <img src={getCloudinaryImageUrl('Assets/assets/Logos/ias_tyk3df.png')} alt="" className='top-2 relative lg:w-[110px] md:w-[80px] w-[80px]'/>
              <span className='mx-3 top-2 relative md:mb-0 sm:mb-2'>Industry Applications Society</span>
            </Link>
            <Link to='' onClick={() => window.location.href='/CIS'} className='lg:w-[11rem] lg:h-[11rem] md:w-[11rem] md:h-[9rem] w-[11rem] h-[9rem] hover:border hover:border-[#4A8BD2] items-center flex rounded-3xl gap-4 flex-col'>
              <img src={getCloudinaryImageUrl('Assets/assets/Logos/cis_ojl0k5.png')} alt="" className='top-2 relative lg:w-[160px] md:w-[120px] w-[120px]'/>
              <div className='flex items-center justify-center'>
              <span className='mx-3 top-[4px] relative'>Computational Intelligence Society</span>
              </div>
            </Link>
            <Link to='' onClick={() => window.location.href='/CS'} className='lg:w-[11rem] lg:h-[11rem] hover:border md:w-[11rem] md:h-[9rem] w-[11rem] h-[9rem] hover:border-[#FAA41A] items-center flex rounded-3xl gap-4 flex-col'>
              <img src={getCloudinaryImageUrl('Assets/assets/Logos/cs_p4nr5g.png')} alt="" className='top-[2rem] relative lg:w-[140px] md:w-[120px] w-[90px]'/>
              <div className='flex items-center justify-center'>
                <span className='mx-3 lg:top-[2.5rem] md:top-[3.4rem] top-[2.5rem] relative'>Computer Society</span>
              </div>
            </Link>
            <Link to='' onClick={() => window.location.href='/WIE'} className='lg:w-[11rem] lg:h-[11rem] md:w-[11rem] md:h-[9rem] w-[11rem] h-[9rem] hover:border hover:border-[#50103C] items-center flex rounded-3xl gap-4 flex-col'>
              <img src={getCloudinaryImageUrl('Assets/assets/Logos/wie_d4jmoi.png')} alt="" className='top-2 relative lg:w-[75px] md:w-[80px] w-[60px]'/>
              <div className='flex items-center justify-center'>
                <span className='md:mx-0 top-2 relative justify-center'>Women In Engineering</span>
              </div>
            </Link>
        </div>
      </div>
    </div>
    
  )
}

export default Units