import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin, faSquareFacebook } from '@fortawesome/free-brands-svg-icons'
import { CloudinaryImage } from '@cloudinary/url-gen';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { quality, format } from '@cloudinary/url-gen/actions/delivery';
const getCloudinaryImageUrl = (publicId) => {
  const myImage = new CloudinaryImage(publicId, { cloudName: 'dtungyafl' });
  return myImage
    .resize(scale().width(1000))  // Resizing the image to 1000px width
    .delivery(quality('auto'))    // Automatically adjust the quality
    .delivery(format('auto'))     // Automatically select the best format (e.g., WebP)
    .toURL();                     // Returns the URL for the transformed image
};


function WIETeam() {
  return (
    <div className="bg-[#ebe5e5] md:h-screen pt-16" id='Team'>
        <div className="px-6 py-10 mx-auto about-title hidd ">
          <h1 className="font-bold text-center text-[#595959] capitalize md:text-3xl text-[22px] tracking-widest">MEET OUR TEAM</h1>
          <h2 className="text-center text-[#A64D79] font-bold text-base pt-2">A Quality And Motivated Board</h2>
          <div className="flex justify-center mx-auto">
                <span className="inline-block w-44 h-[2.5px] bg-[#A64D79] rounded-full opacity-50"></span>
                <span className="inline-block w-4 h-[2.5px] mx-1 bg-[#A64D79] rounded-full opacity-50"></span>
                <span className="inline-block w-2 h-[2.5px] bg-[#A64D79] rounded-full opacity-50"></span>
            </div>
        </div>
        <div className='cont hidd grid md:grid-cols-3 grid-cols-2 pb-10'>
        <div className='flex justify-center items-center flex-col '>
                <img 
          src={getCloudinaryImageUrl('Assets/Bureau/24_elhjxp.jpg')} 
          alt="" 
          className='md:w-[250px] md:h-[250px] w-[180px] h-[180px] flex justify-center rounded-full border-2 border-pink-900 object-cover' 
        />

            <div className='text-[#595959] pt-2'>
              <div className='flex justify-center'>
                <h1 className='font-semibold md:text-xl text-lg'>Malek CHAOUECH</h1>
              </div>
              <div className='flex justify-center md:pt-1 md:pb-1'>
                <h2 className='text-[#A64D79] font-semibold text-lg'>CHAIR</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1 md:pb-0 pb-4'>
                <a href="https://www.facebook.com/chaouch.malakmalouka"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#595959",}} /></a>
                <a href="https://www.instagram.com/chaouch.malak/"><FontAwesomeIcon icon={faInstagram} style={{color: "#595959",}} /></a>
                <a href="https://www.linkedin.com/in/malak-chaouch-005914284/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#595959",}} /></a>
              </div>
            </div>
          </div>

          <div className='flex justify-center items-center flex-col '>
          <img 
          src={getCloudinaryImageUrl('Assets/Bureau/19.jpg')} 
          alt="" 
          className='md:w-[250px] md:h-[250px] w-[180px] h-[180px] flex justify-center rounded-full border-2 border-pink-900 object-cover' 
        />
            <div className='text-[#595959] pt-2'>
              <div className='flex justify-center'>
                <h1 className='font-semibold md:text-xl text-lg'>Hadil MAATOUG</h1>
              </div>
              <div className='flex justify-center md:pt-1 md:pb-1'>
                <h2 className='text-[#A64D79] font-semibold text-lg'>VICE-CHAIR</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1 md:pb-0 pb-4'>
                <a href="https://www.facebook.com/hadoula.maatoug"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#595959",}} /></a>
                {/* <a href="#"><FontAwesomeIcon icon={faInstagram} style={{color: "#595959",}} /></a> */}
                <a href="https://www.linkedin.com/in/hadil-maatoug-0a2968303/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#595959",}} /></a>
              </div>
            </div>
          </div>
          <div className='flex justify-center items-center flex-col md:mt-0 mt-[-16px] relative'>
          <img 
          src={getCloudinaryImageUrl('Assets/Bureau/Copy_of_A3_tcqyki.jpg')} 
          alt="" 
          className='md:w-[250px] md:h-[250px] w-[180px] h-[180px] flex justify-center rounded-full border-2 border-pink-900 object-cover' 
        />
            <div className='text-[#595959] pt-2'>
              <div className='flex justify-center md:pt-1 md:pb-1'>
                <h1 className='font-semibold md:text-xl text-[17px]'>Roua BEN AMMAR</h1>
              </div>
              <div className='flex justify-center'>
                <h2 className='text-[#A64D79] font-semibold text-lg'>MEDIA MANAGER</h2>
              </div>
              <div className='flex justify-center gap-5 text-2xl md:pt-0 pt-1'>
                <a href="https://www.facebook.com/profile.php?id=61550481866492"><FontAwesomeIcon icon={faSquareFacebook} style={{color: "#595959",}} /></a>
                <a href="https://www.instagram.com/roua_ben_ammar/"><FontAwesomeIcon icon={faInstagram} style={{color: "#595959",}} /></a>
                <a href="https://www.linkedin.com/in/roua-ben-ammar/"><FontAwesomeIcon icon={faLinkedin} style={{color: "#595959",}} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default WIETeam